.view-history-font {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    color: #565656;
}

.tiering-benefits-font {
    /* font-family: Hero New; */
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    color: black
}

.scroll-tiering {
    overflow: auto;
    overflow-x: scroll;
    white-space: nowrap;
    width: 100%;
    display: flex;
  }
  
  .scroll-tiering::-webkit-scrollbar {
    display: none;
  }


.tiering-point-card {
    width: 90%;
    background-color: black;
    height: 100px;
    border-radius: 8px;
    color: white;
    position: relative;
}

.privileges-card {
    width: 90%;
    background-color: white;
    height: 310px;
    border-radius: 8px;
    color: black;
    border-radius: 8px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
}

.privileges-font {
    /* font-family: VAG Rounded; */
    font-size: 16px;
    font-weight: 700;
    text-align: left; 
    padding-top: 10px;
    line-height: 22px;
}

.tiering-points-font {
    font-weight: 400;
    line-height: 25px;
    text-align: center;
    display: flex;
    justify-content: space-evenly;
}

.points-font {
    /* font-family: VAG Rounded; */
    font-size: 24px;
    font-weight: 700;
    line-height: 33px;
    text-align: center;
    margin-top: 5px;
}

#medal-radius {
    background: white;
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }

.medal-position {
    width: 60%;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
    margin: auto;
    position: relative;
    top: 12px;
}


.privileges-container {
    display: flex;
    justify-content: space-evenly;
}

.custom-select .rc-select-selector {
    background-color: black;
    color: white;
  }
  
  .custom-dropdown .rc-select-dropdown {
    background-color: black;
    color: white;
  }

  .tier-history-container {
    /* display: flex; */
    /* width: 366px; */
    padding: 24px 16px;
    align-items: center;
    gap: 18px;
    border-radius: 8px;
    border: 0.5px solid #CFCFCF;
    background: #F6F6F6;
  }

  .medal-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-family: 'Hero New', sans-serif; /* Added a fallback */
    width: 112.5px;
    height: 40px;
  }
  