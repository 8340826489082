@media (min-width: 200px) {
	.layout-position {
		bottom: 120px;
		position: relative;
		max-height: 10vh;
	}

	.img-position {
		width: 25%;
		margin-top: 40px;
	}

	.text-position {
		width: 90%;
		font-family: 'Hero New', Arial;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0em;
		margin-top: 15px;
	}
}

/* =========================================== */
@media (min-width: 250px) {
	.layout-position {
		bottom: 120px;
		position: relative;
	}

	/* .register-card {
    width: 100%;
    background-color: black;
    height: 100%;
    border-top-right-radius: 50px;
    color: white;
  } */

	.img-position {
		width: 25%;
		margin-top: 40px;
	}

	.text-position {
		width: 90%;
		font-family: 'Hero New', Arial;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0em;
		margin-top: 15px;
	}

	/* .slick-dots li button:after{
    width: 100px;
  } */
}

/* =========================================== */
@media (min-width: 300px) {
	.layout-position {
		bottom: 120px;
		position: relative;
	}

	.background-height {
		max-height: 10vh;
	}

	.text-position {
		width: 90%;
		font-family: 'Hero New', Arial;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0em;
		margin-top: 15px;
	}

	/* .slick-dots li button:before{
    color: transparent !important;

  }

  .slick-dots li button:after{
    width: 100px;
  } */

	/* .register-card {
    width: 100%;
    background-color: black;
    height: 60vh;
    border-top-right-radius: 50px;
    color: white;
  } */
}

/* =========================================== */
@media (min-width: 350px) {
	.layout-position {
		position: relative;
		bottom: 320px;
	}

	/* .register-card {
    width: 100%;
    background-color: black;
    height: 100%;
    border-top-right-radius: 50px;
    color: white;
  } */

	.img-position {
		width: 25%;
		margin-top: 40px;
	}

	.text-position {
		width: 90%;
		font-family: 'Hero New', Arial;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0em;
		margin-top: 15px;
	}

	.slick-dots li button:after {
		width: 100px;
	}
}

/* =========================================== */
@media (min-width: 400px) {
	.layout-position {
		position: relative;
		bottom: 350px;
	}

	.logo-position {
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		text-align: center;
		z-index: 1;
		margin-top: 20px;
	}

	.text-position {
		width: 80%;
		font-family: 'Hero New', Arial;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		letter-spacing: 0em;
		text-align: left;
	}

	/* .register-card {
    width: 100%;
    background-color: black;
    height: 100%;
    border-top-right-radius: 50px;
    color: white;
  } */
}

/* ======================================= */
@media (min-width: 500px) {
	.layout-position {
		position: relative;
		bottom: 165px;
	}

	.clickBtn {
		width: 85%;
		background-color: transparent;
		margin: auto;
		position: relative;
		top: 500px;
		height: 60px;
	}

	/* .register-card {
    width: 100%;
    background-color: black;
    height: 60vh;
    border-top-right-radius: 50px;
    color: white;
  } */

	/* img {
    width: 50%;
} */

	.logo-position {
		position: absolute;
		margin: auto;
		left: 0;
		right: 0;
		text-align: center;
		z-index: 1;
		width: 30%;
		margin-top: 10px;
	}
}

/* .form-input-box {
	width: 90%;
	margin: auto;
	margin-top: 15px;
} */

/* .form-input {
	background-color: transparent;
	color: white;
	border-radius: 10px;
	border: 2px solid white;
	height: 30px;
} */

.form-input {
	height: auto;
	background: transparent;
	color: white;
	appearance: none;
	-webkit-appearance: none;
}

.form-input input {
	background: transparent;
	color: white;
}

.form-input::-webkit-input-placeholder,
.form-input input::-webkit-input-placeholder {
	color: rgb(130, 129, 129);
}

/* 
::-moz-placeholder {
	color: red !important;
}

::-ms-placeholder {
	color: red !important;
}

::placeholder {
  color
*/

.form-submit-btn {
	background-color: #30e700;
	color: black;
	height: 40px;
	border-radius: 5px;
	font-size: 16px;
	font-weight: 500;
	border: none;
	font-family: 'Hero New', Arial;
}

.tnc {
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	letter-spacing: 0em;
	text-align: left;
}

.logo-position {
	position: absolute;
	margin: auto;
	left: 0;
	right: 0;
	text-align: center;
	z-index: 1;
	margin-top: 20px;
}

.custom-input-container .custom-input::placeholder {
	color: #333;
}

.custom-input-container .custom-input::placeholder .red-text {
	color: red;
}

.cart-checkbox .ant-checkbox-checked .ant-checkbox-inner {
	background-color: black !important;
	border: 1px solid black !important;
}

.placeholder {
	color: white;
	position: relative;
	top: -30px;
	display: flex;
	justify-content: left;
	left: 10px;
}

/* .placeholder:after {
	content: '*';
	color: red;
} */
/* 
.form-input:focus + .placeholder {
	display: none;
} */

/* 
@media (min-width: 500px) {
  .register-card {
    width: 100%;
    background-color: black;
    height: 100%;
    border-top-right-radius: 50px;
    color: white;
  }
} */

.register-card {
	width: 100%;
	background-color: black;
	height: 100%;
	border-top-right-radius: 50px;
	color: white;
}

.green-line {
	background-color: #30EA03;
	height: 3px;
	width: 40%;
	margin-top: 5px;
	border: none;
	margin: auto;
}
