.how-to-earn-points .ant-carousel .slick-dots li button {
  background: transparent;
  opacity: 0.4;
}

.how-to-earn-points .ant-carousel .slick-dots li.slick-active button {
    opacity: 1;
    background: transparent;
}

.how-to-earn-points .slick-dots li button:before{
  color: grey !important;
  line-height: 65px !important;
}

.how-to-earn-points .slick-dots{
  position: relative !important;
  height: 30px;
}  
