    .background-size {
        background-color: #37AF29;
        min-height: 100vh;
        width: 100%;
    }

    .upload-receipt-text {
        font-weight: 700;
        font-size: 32px;
        line-height: 34px;
        text-align: center;
        color: white;
    }

    .upload-receipt-input-file-box {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #FBFBFB;
        border-radius: 4px;
        height: 160px;
        margin-top: 20px;
        margin: 20px auto;
        width: 78%;
      }

    .form-input-receipt {
        width: 100%;
        height: 45px;
        border: 1px solid white;
        margin: 5px;
        color: white;
        background-color: transparent;
      }

    ::-webkit-calendar-picker-indicator {
        filter: invert(1)
    }

    .center-text {
        display: flex;
        justify-content: center;
        text-align: justify;
    }

    .upload-receipt-details-file-box {
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 4px;
        height: 180px;
        margin: 20px auto;
        width: 80%;
      }

    input::placeholder {
        color: white;
      }

    .receipt-details-label {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        color: white;
        margin-bottom: 5px;
        padding-left: 5px;
      }

      .column {
        float:left;
        width: 50%;
        font-size: 12px;
        margin-bottom: 45px;
        line-height: 30px;
        justify-content: center;
       
      }

      .row:after {
        content: "";
        display: table;
        clear: both;
      }

      .parent {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .receipt-sample-pos {
        text-decoration: underline;
        position:relative;
        bottom: 50px;
        text-align: center;
        display: block;
        color: white;
        font-weight: 400;
        font-family: 'Times New Roman', Times, serif;
        font-size: 11px;
      }

      /* Modal */
      .submit-receipt-modal-header {
        font-size: 24px;
        font-weight: 700;
        text-align: center;
        color: black;
        justify-content: center;
        align-items: center;
      }

      .submit-receipt-modal-font {
        font-size: 16px;
        font-weight: 700;
        text-align: left;
        color: black;
      }
      
      .sample-receipt-button {
        width: 80%;
        background: #168037 !important;
        border: 2px solid white;
        border-radius: 15px;
        color: white;
        height: 40px;
        font-weight: 600;
        margin-bottom: 10%; 
      }

      .submit-receipt-success-header {
        color: black;
        justify-content: center;
        align-items: center;
        font-family: 'Inter';
        font-size: 20px;
        font-weight: 700;
        text-align: center;
      }

      .receipt-submit-success-modal-button {
        width: 60%;
        border-radius: 8px;
        font-weight: 600;
        margin-bottom: 10%;
      }

      .text-justify {
        text-align:inter-word;
      }

      .receipt-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: black;
        /* height: 310px; */
        border-radius: 12px 12px 12px 12px;
        color: white;
        text-align: center;
      }

      .receipt-container img {
        max-width: 100%;
        max-height: 100%;
      }

      /* After receceipt upload */
      .after-receipt-container {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        height: 17vh;
        border-radius: 10px 10px 10px 10px;
        color: white;
        text-align: center;
        position: relative;
      }

      .after-receipt-container img {
        max-width: 100%;
        max-height: 100%;
      }
    
      .center {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 15vh;
        position: relative;
        top: 100px;
      }

      .submit-receipt-btn {
        background: #30E700 !important;
        border-radius: 10px;
        height: 5vh;
        font-weight: 600;
        width: 100% !important;
        border: none;
      }

      .sample-icon {
        position: absolute;
        right:10px;
        bottom: 12px;
        width: 8%;
      }

      .slick-dots li button:before{
        /* color: #FA00FF !important; */
        line-height: none  !important;
        font-size: 12px !important;
      }

      .qr-container, .ant-modal-content {
        border-radius: 8px !important;
        border: none;
        box-shadow: 0px 10px 4px rgba(0, 0, 0, 0.25) !important;
      }
    
      .receipt-success-button {
        width: 100%;
        /* background: #02BC7D !important; */
        border: none;
        border-radius: 10px;
        color: white;
        height: 40px;
        font-weight: 600;
        font-family: 'Hero New';
      }

     .hover-img:hover {
      opacity:0.5;
     }

/* ======================================================================== */
/* Choosing between tng & grab images */
    .img-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100px;
      gap: 20px;
    }

    .img-container img {
      max-height: 56px;
      opacity: 0.5; 
    }

    .img-container img.selected {
      opacity: 1; 
    }

    @media (min-width: 500px) {
      .sample-icon {
        position: absolute;
        right:10px;
        bottom: 12px;
        width: 6%;
      }

      .img-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        gap: 20px;
        width: 100%;
      }

      .img-container img {
        max-height: 56px;
        opacity: 0.5; 
      }
  
    }

.upload-receipt-header {
  font-size: 24px;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
}

.upload-receipt-desc {
  font-family: 'Roboto', sans-serif; /* Added a fallback */
  font-size: 16px;
  font-weight: 400;
}

    