.badges-container {
    /* display: flex; */
    padding: 16px;
    /* align-items: center; */
    gap: 16px;
    border-radius: 8px;
    border: 0.5px solid #EAEAEA;
    background: #F5F5F5;
}

.claimed-btn {
    color: #7C878E;
    font-family: 'Hero New', sans-serif;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    width: 25%;
    margin-right: 2%;
  }
  
.redeem-btn {
    color: #000000;
    border-radius: 6px;
    border: none;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Hero New', sans-serif; 
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
    text-align: center;
  }
  
