.submission-history-card {
    background-color: white;
    border-radius: 8px;
  }

.select-tag .ant-select-selector {
  width: 130px !important;
  border-radius: 16px !important;
  color: white !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  background-color: black;
}

.select-tag .ant-select-selection-item {
  color: white !important;
}

.select-dropdown {
  background: black !important;
  color: white !important
}

/* Style the select options */
.select-dropdown .ant-select-item {
  background: black !important;
  color: #FFF !important;
}

.select-dropdown .ant-select-selection-item {
  color: white !important;
}

.select-dropdown .ant-select-selection-placeholder {
  color: white !important;
}

.title {
  font-weight: 600;
  font-family: 'Hero New', Arial;
  font-size: 16px;
  text-align: left;
}

