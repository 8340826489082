 /* =========================================== */
 @media (min-width: 200px) {
  .layout-position {
    bottom: 120px;
    position: relative;
    max-height: 10vh;
  }

  .clickBtn {
    width: 85%;
    background-color: transparent;
    margin: auto;
    position:absolute;
    top: 170px;
    left: 18px;
    height: 70px;
  } 
}

 /* =========================================== */
@media (min-width: 250px) {
  .layout-position {
    bottom: 120px;
    position: relative;
    max-height: 10vh;
  }

  .clickBtn {
    width: 85%;
    background-color: transparent;
    margin: auto;
    position:absolute;
    top: 215px;
    left: 20px;
    height: 70px;
  }


  .logo-position{
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1;
    margin-top: 20px;
  } 
  
}

 /* =========================================== */
@media (min-width: 300px){
  .layout-position {
    bottom: 120px;
    position: relative;
    max-height: 10vh;
  }

  .background-height {
    max-height: 10vh;
  }

  .clickBtn {
    width: 85%;
    background-color: transparent;
    margin: auto;
    position:absolute;
    top: 260px;
    left: 20px;
    height: 75px;
  }

  .logo-position{
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1;
    margin-top: 20px;
  } 

}

 /* =========================================== */
@media (min-width: 350px){
  .layout-position {
    position: relative; 
    bottom: 170px;
  }

  .carousel-img-position {
    width: 25%;
    margin-top: 40px;
  }

  .text-position {
    /* max-width: 100%; */
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-top: 15px;
  }

  .h1 {
    padding-top: 20px;
    font-family: 'Hero New', arial;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0em;
  }
  
  .logo-position{
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1;
    margin-top: 20px;
  } 

}

 /* =========================================== */
@media (min-width: 400px) {

  .layout-position {
    position: relative; 
    bottom: 165px;
  }

  .clickBtn {
    width: 85%;
    background-color: transparent;
    margin: auto;
    position: relative;
    top: 400px;
    height: 60px;
  }

  /* .text-position {
    width: 100%;
    font-weight: 400;
  } */

  .h1 {
    padding-left: 20px;
    padding-top: 20px;
  }

}

/* ================================================ */
@media (min-width: 500px) {

  .layout-position {
    position: relative; 
    bottom: 165px;
  }

  .clickBtn {
    width: 85%;
    background-color: transparent;
    margin: auto;
    position: relative;
    top: 500px;
    height: 60px;
  }

}

/* ================================================ */
@media (min-width: 1500px) {
  
  .layout-position {
    position: relative; 
    bottom: 165px;
  }

  .logo-position{
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1;
    width: 30%;
    margin-top: 10px;
  } 
}

.how-it-works-card {
  width: 100%;
  background-color: black;
  border-top-right-radius: 50px;
  color: white;
  padding-bottom: 5vh;
  height: 45vh;
}


.carousel-slider .slick-dots {
  display: flex !important;
  align-items: center !important;
  justify-content: left !important;
}

.carousel-slider .slick-dots li {
  margin: 0 24px 0 24px; 
}

.carousel-slider .slick-dots li button {
  width: 57px;
}

.carousel-slider .slick-dots li:first-child {
  margin-left: 0; /* No left margin for the first item */
}

.carousel-description{
  max-width: '100%';
  line-height: '20px';
  font-weight: '400';
  font-size: '16px';
}
