.campaign-text {
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
}

hr {
  height: 1px;
  background-color: lightgray;
  border: none;
}

.subTitle {
  font-family: 'Hero New', arial;
  font-size: 15px;
  font-weight: 500;
  line-height: 23px;
  text-align: left;
}

.subContent {
  line-height: 28px;
  letter-spacing: .5px;
  font-size: 12px;
  /* font-weight: 500; */
  text-align: justify;
  justify-content: left;
}

.font-arial {
  font-family: 'Hero New', arial;
}


.use-tick {
  list-style-type: none;
  
}


ul.use-tick li:before {
  content: '✓';
}

.content {
  text-align: justify;
  text-justify: inter-word;
  line-height: 1.5em;
  font-size: 16px;
}
