.article-font {
    color: black;
    font-size: 25px;
    font-weight: 500;
    text-align: left;
}

.active-btn {
    display: flex;
    padding: 6px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #30E700;
    border: none;
}

.inactive-btn {
    display: flex;
    padding: 6px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #C2C2C2;
    border: none;
    color: white;
}

.img-adjustment {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.img-circle-adjustment {
    width: 25px;
    margin-right: 10px;
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}
  
.grid-container > div {
    /* text-align: center; */
    font-size: 30px;
}
  
.article-container {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 10px;
    position: relative;
  }

.article-title-font {
    color: var(--p-3, #000);
    font-size: 14px;
    font-weight: 700;
    line-height: 150%;
}

.author-name-font {
    color: var(--p-3, #000);
    font-size: 12px;
    font-weight: 500;
    line-height: 110%;
}

.time-read-font {
    color: #707070;
    font-size: 12px;
    line-height: 110%;
}

.item1 {
    grid-row-start: 1;
    grid-row-end: 3;

}

.item2 {
    grid-row-start: 3;
    grid-row-end: 6;

}

.item3 {
    grid-row-start: 1;
    grid-row-end: 4;
}

.item4 {
    grid-row-start: 4;
    grid-row-end: 6;
}
