@import url('./Fonts/Gotham/style.css');
@import url('https://fonts.cdnfonts.com/css/hero-new');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600&display=swap');


.App {
    /* min-height: 100vh; */
    margin: 0 auto !important;
    max-width: 500px;
    background-color: white;
}

.ml-1 {
    margin-left: 10px;
}

.ml-2 {
    margin-left: 20px;
}

.ml-3 {
    margin-left: 30px;
}

.ml-4 {
    margin-left: 40px;
}

.mt-1 {
    margin-top: 10px;
}

.mt-2 {
    margin-top: 20px;
}

.mt-3 {
    margin-top: 30px;
}

.mt-4 {
    margin-top: 40px;
}

.mt-5 {
    margin-top: 50px;
}

.t-white {
    color: white;
}

.t-black {
    color: black
}

.t-green {
    color: #30E700;
}

.t-pink {
    color: #FA00FF;
}

.text-deco-none {
    text-decoration: none;
}

.text-deco-underline {
    text-decoration: underline;
}

.d-flex {
    display: flex;
}

.d-inline {
    display: inline;
}

.w-90 {
    width: 90%;
}

.w-10 {
    width: 10%;
}

.w-50 {
    width: 50%;
}

.w-100 {
    width: 100%;
}

.text-center {
    text-align: center;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.mr-1 {
    margin-right: 10px;
}

.mr-2 {
    margin-right: 20px;
}

.mr-3 {
    margin-right: 30px;
}

.mr-4 {
    margin-right: 40px;
}

.fontSize-12 {
    font-size: 12px;
}

.fontSize-14 {
    font-size: 14px;
}

.fontSize-16 {
    font-size: 16px;
}

.fontSize-18 {
    font-size: 18px;
}

.fontSize-20 {
    font-size: 20px;
}

.fontSize-24 {
    font-size: 24px;
}

.fontSize-25 {
    font-size: 25px;
}

.fontSize-32 {
    font-size: 32px;
}

.font-weight-300 {
    font-weight: 300;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-500 {
    font-weight: 500;
}

.font-weight-600 {
    font-weight: 600;
}

.font-weight-700 {
    font-weight: 700;
}

.font-family-hero-new {
    font-family: 'Hero New', arial;
}

.font-family-roboto {
    font-family: 'Roboto';
}

.font-family-inter {
    font-family: 'Inter';
}

.cursor-pointer {
    cursor: pointer;
}

.my-1 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.justify-content-center {
    justify-content: center;
}

.user-container {
    /* width: 90%; */
    background-color: black;
    height: 170px;
    border-radius: 25px 25px 25px 25px;
    color: white;
}

.ant-progress .ant-progress-inner {
    background: white;
    width: 100%;
}

.footer-container {
    width: 100%;
    background-color: black;
    display: flex;
    justify-content: space-between;
    text-align: center;
    align-items: center;
    padding-bottom: 1%;
}

.upload-receipt-foooter {
    font-size: 12px;
    font-weight: 400;
}

.img-upload-receipt {
    width: 68%;
}

.upload-receipt-foooter {
    position: relative;
    top: 3px;
}

@media (min-width: 500px) {

    .footer-container {
        width: 500px;
        background-color: black;
        display: flex;
        justify-content: space-between;
        text-align: center;
        align-items: center;
    }

    .haleon-logo {
        width: 100%;
    }

}



@media (min-width : 1000px) {

    .haleon-logo {
        width: 110px;
    }

    .img-upload-receipt {
        width: 80%;
    }

}


.ad-container {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ad-container-for-stores {
    position: relative;
    float: right;
    bottom: 230px;
    left: 10px;
}

/* Help Center page */
.ad-container-help-center {
    position: absolute;
    /* position: absolute; */
    margin-left: 260px;
    margin-top: -330px;
}

@media (min-width: 1200px) {

    .ad-container-help-center {
        position: absolute;
        margin-left: 420px;
        bottom: 200px;
    }


}

/* ==================================== */
/* .ant-carousel {
    height: 40vh;
} */


.coming-soon-button {
    width: 90%;
    background: #30E700 !important;
    border: none;
    border-radius: 5px;
    height: 35px;
}


.ant-drawer .ant-drawer-header .overflow-btn {
    border-bottom: none;
}

.ant-drawer .ant-drawer-body {
    padding: 0px 0px 0px 35px;
    overflow: auto;
}

.ant-drawer .ant-drawer-title {
    justify-content: center;
    display: flex;
}

.overflow-btn .ant-drawer-close {
    position: absolute;
    top: 20px;
    right: 0;
}

.minimized-panel {
    width: 90%;
    margin: 5px 20px;
}


.admin-portal-dropdown .ant-select-selector {
    background-color: white !important;
    border-radius: 5px !important;
    height: 5vh !important;
    color: black !important;
    text-align: left;
}

.admin-portal-dropdown .ant-select-selection-item {
    color: black !important;
}

.admin-portal-dropdown .ant-select-selection-placeholder {
    color: gray !important;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=text] {
    -moz-appearance: textfield;
}

.whatsapp-img {
    width: 18%;
    z-index: 1;
    position: absolute;
    left: 5px;
    margin-top: 10px;
}


@media (min-width : 600px) {
    .whatsapp-img {
        width: 15%;
        z-index: 1;
        position: absolute;
        left: 5px;
        margin-top: 10px;
    }
}

.receipt-sample-modal .ant-modal-body {
    height: 380px;
}


.login-text-color.ant-form-item .ant-form-item-explain-error {
    font-size: 12px;
    color: red !important;
    padding-top: 3%;
    padding-left: 6%;
}

.input-placeholder.ant-input-affix-wrapper>input.ant-input {
    color: white !important;
    background-color: transparent !important;
}


.options-checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #26D175;
    border: none;
}


.products-checkbox .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1C1B1F;
    border: none;
    opacity: 0.38;
}

.sg-carousel-container.ant-carousel {
    height: none;
}


.sg-carousel-container .slick-dots li.slick-active button:before {
    /* position: relative; */
    color: #26D175 !important;
}

.sg-carousel-container .slick-dots li button:before {
    line-height: 55px !important;
}

.sg-carousel-container .slick-dots li button {
    margin-top: 13px;
}


.sg-carousel-container .slick-dots {
    position: relative !important;
    height: 48px;
}

.receipt-sample-modal .slick-dots li.slick-active button:before {
    /* position: relative; */
    color: #FA00FF !important;
}

.receipt-sample-modal .slick-dots li button:before {
    line-height: 55px !important;
}

.receipt-sample-modal .slick-dots li button {
    margin-top: 13px;
}



.receipt-sample-modal .slick-dots {
    position: relative !important;
    height: 48px;
}

.contact-checkbox.ant-switch.ant-switch-checked .ant-switch-inner {
    background-color: #30E700;
}


.loading-text {
    text-align: center;
    animation: loading 1s infinite alternate;
  }

  /* @keyframes loading {
    0% { opacity: 0; }
    100% { opacity: 1; }
  } */


  .ant-spin .ant-spin-dot-item  {
    background-color: #30E700;
  }

.total-unit-balance{
    font-size: 33px;
    font-weight: 800;
    text-align: right;
    font-family: 'Hero New', sans-serif;
}

.loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #30E700;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1.5s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  