 /* =========================================== */
 /* @media (min-width: 200px) { */
    .layout-position {
      bottom: 120px;
      position: relative;
      max-height: 10vh;
    }

    .logo-position{
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      text-align: center;
      z-index: 1;
      margin-top: 10px;
    } 

    .getStarted-card {
      width: 100%;
      background-color: black;
      height: 50vh;
      border-top-right-radius: 50px;
      color: white;
    }

    .h1 {
    font-size: 32px;
    font-weight: 600;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: center;
    }

    .createBtn {
      background-color: #30E700;
      text-decoration: none;
      width: 80%;
      height: 35px;
      border: 1px solid transparent;
      border-radius: 5px;
    }

    .loginBtn {
      background-color: transparent;
      text-decoration: none;
      width: 80%;
      height: 35px;
      border: 1px solid white;
      border-radius: 5px;
      color: white;
    }


    .getStartedBtn {
      background-color: #30E700;
      text-decoration: none;
      width: 100%;
      height: 35px;
      border: 1px solid transparent;
      border-radius: 5px;
      text-align: center;
      margin-top: 50px;
      font-weight: 500;
      font-size: 16px;
    }

  /* } */
  
   /* =========================================== */
  @media (min-width: 250px) {
    .layout-position {
      bottom: 120px;
      position: relative;
      max-height: 10vh;
    }

    .getStarted-card {
      width: 100%;
      background-color: black;
      height: 60vh;
      border-top-right-radius: 50px;
      color: white;
      /* text-align: center; */
    }

    .h1 {
    font-size: 32px;
    font-weight: 600;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: center;
    }

    .createBtn {
      background-color: #30E700;
      text-decoration: none;
      width: 80%;
      height: 35px;
      border: 1px solid transparent;
      border-radius: 5px;
    }

    .loginBtn {
      background-color: transparent;
      text-decoration: none;
      width: 80%;
      height: 35px;
      border: 1px solid white;
      border-radius: 5px;
      color: white;
    }
    
    .getStartedBtn {
      background-color: #30E700;
      text-decoration: none;
      width: 100%;
      height: 35px;
      border: 1px solid transparent;
      border-radius: 5px;
      text-align: center;
      margin-top: 50px;
    }

  }
  
   /* =========================================== */
  @media (min-width: 300px){
    .layout-position {
      bottom: 120px;
      position: relative;
      max-height: 10vh;
    }
  
    .background-height {
      max-height: 10vh;
    }

    .logo-position{
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      text-align: center;
      z-index: 1;
      margin-top: 20px;
    } 

    .getStarted-card {
      width: 100%;
      background-color: black;
      height: 30vh;
      border-top-right-radius: 50px;
      color: white;
      /* text-align: center; */
    }

    .h1 {
    font-size: 32px;
    font-weight: 600;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: center;
    }

    .createBtn {
      background-color: #30E700;
      text-decoration: none;
      width: 80%;
      height: 35px;
      border: 1px solid transparent;
      border-radius: 5px;
    }

    .loginBtn {
      background-color: transparent;
      text-decoration: none;
      width: 80%;
      height: 35px;
      border: 1px solid white;
      border-radius: 5px;
      color: white;
    }
    
  }
  
   /* =========================================== */
  @media (min-width: 350px){
    .layout-position {
      position: relative; 
      bottom: 160px;
    }

    .logo-position{
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      text-align: center;
      z-index: 1;
      margin-top: 20px;
    } 
    
    .getStartedBtn {
      background-color: #30E700;
      text-decoration: none;
      width: 100%;
      height: 35px;
      border: 1px solid transparent;
      border-radius: 5px;
      text-align: center;
      margin-top: 50px;
    }

    .getStarted-card {
      width: 100%;
      background-color: black;
      height: 60vh;
      border-top-right-radius: 50px;
      color: white;
      /* text-align: center; */
    }

    .h1 {
    font-size: 32px;
    font-weight: 600;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: center;
    }

    .createBtn {
      background-color: #30E700;
      text-decoration: none;
      width: 80%;
      height: 35px;      
      border: 1px solid transparent;
      border-radius: 5px;
    }

    .loginBtn {
      background-color: transparent;
      text-decoration: none;
      width: 80%;
      height: 35px;      
      border: 1px solid white;
      border-radius: 5px;
      color: white;
    }

    .verify-btn {
      
        font-size: 10px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
    }
  
  }

  /* =========================================== */
  
  @media (min-width: 1000px) {
  
    .layout-position {
      position: relative; 
      bottom: 165px;
    }

    /* img {
        width: 50%;
    } */

    .logo-position{
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      text-align: center;
      z-index: 1;
      width: 30%;
      margin-top: 10px;
    } 
  }
  
   /* =========================================== */
  p {
    font-family: 'Hero New';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }
