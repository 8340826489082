.done-button {
    font-size: 16px;
    border-radius: 8px;
    background: #30E700;
    color: black;
    border: none;
    font-weight: 500;
    height: 40px;
    width: 90%;
    font-family: 'Hero New', sans-serif; /* Added a fallback */
  }
  