  .display-store-info-container {
    display: flex;
    height: 110px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4px;
    border: 1px solid black;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
  }

  /* .ant-select-selector {
    background-color: black !important;
    height: 40px !important;
    align-items: center;
  } */

  /* .ant-select-selection-search {
    color: black !important;
  } */
/* 
  .ant-select-selection-item {
    color: white !important;
  }
  
  .ant-select-selection-placeholder {
    color: white !important;
  } */
