.management-table {
    width: 100%;
    /* margin-top: 10px; */
}

/* .ant-table-cell {
    overflow:scroll;
} */


.success-modal-msg {
    text-align: center;
    color: green;
}

.error-modal-msg {
    text-align: center;
    color: red;
}

.validate-modal .ant-modal-content {
    width: 1220px;
    margin-left: -55%;
    /* margin-right: 50%; */

}

.validate-input{
    width:300px;
    text-align: center;
}

.ant-table-cell {
    width: 20%;
}

.table-container {
    background-color: white;
    max-height: 600px;
    overflow: auto;
  }
