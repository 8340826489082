.center-all {
  justify-content: center;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: hsla(0, 0%, 100%, 0.65);
  background: white;
}

.main-home {
  width: 100%;
}

.ant-btn {
  /* border-radius: 8px;
  width: 40%;
  height: 40px;
  font-size: 18px; */
}