.copy-link-container {
    display: flex;
    width: 366px;
    height: 58px;
    padding: 8px;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    background-color: black;
}


.copy-btn {
    display: flex;
    width: 75px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    color: black;
    border-radius: 8px;
    background: #30E700;
    border: none;
}

.title {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Inter';
}

.description {
    font-size: 14px;
    font-weight: 400;
    font-family: 'Inter';
}

.refer-link-container {
    background-color: black;
    display: flex;
    height: 60px;
    border-radius: 12px;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 6px;
    gap: 5px;
  }

.refer-link-text {
    color: white;
    align-items: center;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    font-family: 'Inter', sans-serif; /* Added a fallback */
}
  
  