.loading-home {
    display: flex;
    justify-content: center;
  }

  .img-position {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }