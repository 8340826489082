@font-face {
  font-family: 'SVNOmnes';
  src: local('SVNOmnes'),
    url('./Fonts/SVN-Omnes-Medium.otf') format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'SVNOmnes-Bold';
  src: local('SVNOmnes-Bold'),
    url('./Fonts/SVN-Omnes-Bold.otf') format('truetype');
  font-style: bold;
}

body {
  margin: 0;
  font-family: 'SVNOmnes';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  /* height: 100%; */
  margin: 0;
  /* color: var(--blue) */
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

a {
  text-decoration: none;
}

:root {
  --primary: #FEDD00;
  --secondary: #FA00FF;
  --gray: #E0E7EC;
  --blue: #253690;
  --red: #FE027E;
  --yellow: #FFD63E
}

.image-contain {
  object-fit: contain;
  ;
}

.bold-text {
  font-family: 'SVNOmnes-Bold';
  font-weight: 700;
}

.normal-bold-text {
  font-family: 'SVNOmnes-Bold';
  font-weight: 500;
}

/* .blue-tex {
  color: var(--blue);
} */

.primary-bg {
  background: var(--primary);
}

.secondary-bg {
  background: var(--secondary);
}

.border-round-6 {
  border-radius: 6px;
}

/* font headers and text sizes */
.xl-header-text {
  font-family: 'SVNOmnes-Bold';
  font-size: 2rem;
  font-weight: 700;
  color: var(--blue);
  text-transform: uppercase;
}

.register-form-name {
  font-family: 'SVNOmnes-Bold';
  font-size: 1rem;
  color: #253690;

}

.l-header-text {
  font-size: 1.55rem;
  font-weight: 700;
  color: var(--blue);
  text-transform: uppercase;
}

/* .m-header-text {
  margin-top: 3%;
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--blue);
  text-transform: uppercase;
} */

.header-text {
  font-size: 22px;
  font-weight: 600;
  color: var(--blue);
  text-transform: uppercase;
  line-height: 21.2px;
}

.title-prenatal-text {
  font-size: 20px;
  font-weight: 600;
  color: var(--blue);
  line-height: 21.2px;
}

.l-header-text-sm-bold {
  font-size: 25px;
  font-weight: 600;
  color: var(--blue);
  text-transform: uppercase;
  line-height: 21.2px;
}

.normal-text {
  font-size: 1rem;
  font-weight: 600;
  color: var(--blue);
  text-transform: uppercase;
}

.normal-text-lower {
  font-size: 1rem;
  font-weight: 600;
  color: var(--blue);
}

.red-text {
  color: var(--red) !important;
}


.cursor-pointer {
  cursor: pointer;
}

/* button styles  */
.primary-button {
  font-family: 'SVNOmnes-Bold';
  background: var(--primary);
  border-radius: 43px;
  padding: 4px 0px;
  width: 100%;
  border: none;
  color: #FFFFFF !important;
  display: flex;
  justify-content: center;
  height: 45px;
}

.secondary-button {
  font-family: 'SVNOmnes-Bold';
  background: var(--primary);
  border-radius: 43px;
  padding: 4px 0px;
  width: 100%;
  border: none;
  color: #FFFFFF !important;
  display: flex;
  justify-content: center;
  height: 30px;
}

.fb-button {
  font-family: 'SVNOmnes-Bold';
  border-radius: 43px;
  padding: 4px 0px;
  width: 50vw;
  display: flex;
  justify-content: center;
  height: 35px;
}

.primary-button-bg-white {
  font-family: 'SVNOmnes-Bold';
  background: #FFFFFF;
  border-radius: 43px;
  padding: 4px 0px;
  width: 100%;
  border: 1px solid var(--primary);
  color: var(--primary);
  height: 45px !important;
  font-size: 1.2rem;
}

.l-button-text {
  font-family: 'SVNOmnes-Bold';
  color: #FFFFFF;
  font-size: 1.2rem;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  border: none;
}

.fb-button-text {
  font-family: 'SVNOmnes-Bold';
  color: #FEDD00;
  background: white;
  font-size: 1.2rem;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  /* font-weight: 700; */

}

.m-button-text {
  font-family: 'SVNOmnes-Bold';
  color: #FFFFFF;
  font-size: 1.1rem;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  border: none;
}

.m-button-text-bg-white {
  color: var(--primary);
  font-size: 1.1rem;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  height: 35px;
}


.mobile-content-container {
  padding: 0 10% 0 10%;
}

.border-bottom-gold {
  border-bottom: 1px solid var(--primary);
}

.help-title-bold {
  font-weight: bold;
  color: var(--blue);
}

.help-title-content {
  font-weight: 500;
  color: var(--blue);
}

.normal-line-height {
  line-height: 20px;
}

.header-line-height {
  line-height: 30px;
  margin-left: -1rem
}

.back-btn {
  padding: 10px;

}


.ant-input,
.ant-picker,
.ant-select,
.ant-select-selector,
.ant-select-open {
  box-shadow: none !important;
}

.yellow-content-bg {
  background-color: var(--secondary);
  border-radius: 10px;
  padding: 2%
}

.primary-content-bg {
  background-color: var(--primary);
  border-radius: 10px;
  padding: 10px 0px 10px 10px
}

.capitalize{
  text-transform: capitalize;
}

.copied {
  position: fixed;
  top: 40%;
  right: 20%;
  width: 55%;
  margin: 0 auto;
  padding: 30px 5px;
  border-radius: 10px;
  text-align: center;
  background-color: rgba(18, 16, 16, 0.85);
}


/* for desktop and tablets */
@media (min-width: 480px) {

  /* desktop ui css  */
  .desktop-content-container {
    margin: 0 20% !important;
  }


  .desktop-content-container-less-margin {
    margin: 0 5% !important;
  }

  .desktop-content-container-margin {
    margin: 0 10% !important;
  }

  .primary-button {
    margin: 0 auto;
    min-width: 35%;
    font-size: 1.5rem;
    max-width: 50%
  }


  .primary-button-bg-white {
    margin: 0 auto;
    min-width: 35%;
    font-size: 1.5rem;
    max-width: 50%
  }

  .primary-button-bg-white {
    background: transparent
  }

  .desktop-content-right-space {
    margin-right: 3rem
  }

  .desktop-right-content-bg {
    background: var(--secondary);
    border-radius: 0px 0px 30px 30px;
    padding: 2rem 2rem 1rem 2rem;
  }

  .desktop-partial-header-bg {
    background: var(--secondary);
    border-radius: 30px 30px 0px 0px;
    padding: 2rem 2rem 1rem 2rem;
  }

  .desktop-right-full-content-bg {
    background: var(--secondary);
    border-radius: 30px;
    padding: 2rem 2rem 3rem 2rem;
  }

  input.ant-input,
  .ant-select-selector {
    background: transparent;
  }

  .ant-select-selector {
    background-color: transparent !important;
  }


  .ant-input:focus,
  .ant-picker:focus,
  .ant-select-selector:focus,
  .ant-select:focus,
  .ant-select-open:focus {
    border-color: var(--blue) !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(255, 202, 8, 0.2);
    box-shadow: 0 0 0 2px rgba(255, 202, 8, 0.2);
  }

  .ant-input,
  .ant-picker,
  .ant-select,
  .ant-select-selector,
  .ant-select-open {
    border-color: var(--blue) !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(255, 202, 8, 0.2);
    box-shadow: 0 0 0 2px rgba(255, 202, 8, 0.2);
  }

  .point-shop-image-border {
    border: 2px solid var(--primary);
    border-radius: 10px;
    padding: 1.2rem 8px
  }

  .point-shop-image-border-view-single {
    border: 2px solid var(--primary);
    border-radius: 10px;
    padding: 2rem 8px
  }

  .m-header-text {
    font-family: 'SVNOmnes-Bold';
    font-size: 2rem;
    font-weight: 700;
    color: var(--blue);
    text-transform: uppercase;
  }

  .desktop-voucher-code-title {
    font-family: 'SVNOmnes-Bold';
    font-size: 2rem;
    font-weight: 500;
    color: var(--blue);
    text-transform: uppercase;

  }

  .desktop-voucher-code-desc {
    font-family: 'SVNOmnes';
    font-size: 1.2rem;
    font-weight: normal;
    color: var(--blue);
  }

  .prenatal-img {
    object-fit: contain !important;
    margin: 1rem 0 !important
  }

  .more-tips-prenatal-img {
    width: 25vw !important;
  }

}

/* autofill ios color fix */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-transition-delay: 9999s;
	transition-delay: 9999s;
}
