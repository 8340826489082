.form-item-label {
  font-size: '16px'!important;
  font-weight: 300;
  /* color: black; */
}

.redirection-box {
  /* background-color: black; */
  background-color: white;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #7C878E;
  border-radius: 6px;
  /* color: #7C878E; */
} 

.arrow-pos {
  /* transform: rotate(180deg); */
  width: 5%;
  height: 5%;
  align-items: center;
}

.logout-btn {
  background: #7C878E !important;
  border-radius: 6px;
  height: 5vh;
  font-weight: 600;
  width: 100% !important;
  border: none;
  color:white;
  font-size: 16px;
  font-family: 'Hero New', Arial;
}

.save-btn {
  background: #30E700 !important;
  border-radius: 6px;
  height: 5vh;
  font-weight: 500;
  width: 100% !important;
  border: none;
  color:black;
  font-size: 16px;
  font-family: 'Hero New', Arial !important;
}

.submit-receipt-success-header {
  color: black;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.update-details-success-header {
  color: black;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
}

.receipt-success-button {
  width: 100%;
  /* background: #02BC7D !important; */
  border: none;
  border-radius: 10px;
  color: white;
  height: 40px;
  font-weight: 500 !important;
}

.profile-title-font {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  font-family: 'Hero New', Arial;
}

.input-type-month::-webkit-calendar-picker-indicator {
  filter: invert(0);
}

.input-type-month {
  color: black; 
}

