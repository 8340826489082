.pickup-container {
    background-color: white !important;
    border-radius: 10px;
    padding-left: 16px;
    height: 10vh;
    padding-top: 10px;
  }

.change-outlet-btn {
    width: 45%;
    background-color:black !important;
    padding: 10px 16px;
    border-radius: 20px 0px 10px 0px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    color: white;
}

.adjust-quantity-btn {
  border-radius: 50px;
  width: 20px;
  height: 20px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-checkbox-input {
  background-color: aliceblue;
  
}

.add-to-cart-width {
    width: 80%;
}

.add-to-cart-container {
    background-color: black;
    height: 100px;
    border-top-left-radius: 50px;
    color: white;
    position: fixed;
    bottom: 0;
    width: 100%;
}


@media (min-width: 500px) {
  .add-to-cart-width {
    width: 30%;
}


.add-to-cart-container {
  background-color: black;
  height: 100px;
  border-top-left-radius: 50px;
  color: white;
  position: fixed;
  bottom: 0;
  width: 500px;
}



}