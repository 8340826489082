.loading-retailer {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.ret-login {
    display: flex;
    height: auto;
    align-items: center;
    flex-direction: column;
    margin-bottom: 40px;
}

.ret-login-logo-container {
    text-align: center;
    margin-top: 60px;
}

.ret-login-logo {
    width: 100%;
}

.ret-partner-program-logo {
    width: 60%;
}

.ret-login-pic {
    width: 80%;
}

.ret-login-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ret-login-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 37px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 20%;
}

.ret-login-subtitle {
    /* font-family: lightFont; */
    font-size: 16px;
    text-align: center;
    margin-top: 5%;
    color: #1B1C1A;
}

.ret-login-input-container {
    margin-top: 15%;
    text-align: center;
}

.ret-verify-container {
    width: 80%;
}

.ret-verify-loading-btn {
    border: none;
    color: orange;
    font-size: 55px
}

.ret-form-input-login {
    height: 50px;
    background-color: #E8E8E8;
    /* border-radius: 15px; */
    font-size: 15px;
    width: 80%;
    font-weight: 600;
}

.ret-form-submit-btn {
    background: linear-gradient(270deg,
            #f2b81b 2.61%,
            #f0c31b 2.62%,
            #e35c19 59.88%,
            #e21f19 96.6%) !important;
    /* border-radius: 8px; */
    color: white;
    height: 40px;
    border: none;
    font-weight: 600;
    width: 100% !important;
    font-size: medium;
}

.ret-verify-modal-btn {
    margin-top: 5%;
    background: #0EB7;
    /* border-radius: 8px; */
    color: white;
    height: 40px;
    border: none;
    font-weight: bold;
    width: 40%;
}

.ret-resend-otp {
    font-weight: bold;
    font-size: medium;
    padding-top: 10px;
}

.ret-bold-font {
    margin-top: 20px;
    font-size: 31px;
    font-weight: bold;
    font-style: normal;
    text-align: center;
}

.ret-submission-history-card {
    background-color: white;
    border-radius: 8px;
  }
