.btn-color {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  background-color: #C2C2C2;
  color:white;
  width: 50%;
  height:5vh;
}

.all-btn {
  border-radius: 10px;
  text-align: center;
  color: white;
  font-size: 16px;
  border: none;
  width:30%;
  height:3.5vh;
}

.my-reward-card {
  background-color: white;
  border-radius: 10px;
  width: 100%;
  /* padding-left: 10px; */
}

.px-1 {
  padding-left: 10px;
  padding-right: 10px;
}

.py-1 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.redeemItem-active-btn {
  width: 100%;
  padding: 4px 8px;
  border-radius: 10px;
  background-color: #30E700;
  border: none;
  color: #000;
  font-size: 14px;
  font-weight: 500;
}

.redeemItem-inactive-btn {
  width: 100%;
  padding: 4px 8px;
  border-radius: 10px;
  background: #C2C2C2;
  border: none;
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
}



.rewards-point-container {
  display: flex;
  padding: 4px 10px;
  align-items: center;
  border-radius: 6px;
  background-color:  #D9D9D9;
  color: #000;
  font-family: Hero New;
  font-size: 16px;
  font-weight: 600;
}

.pointShop-container {
  display: grid;
  grid-template-columns: 45% 45%;
  /* gap: 8px; */
  justify-content: space-between;
}

.select-option {
  background-color: black;
  color: white;
}

.ant-select-selector {
  background-color: black !important;
  align-items: center !important;
  border-radius: 16px !important;
  width: 95px !important;
  height: 28px !important;
}

.badges-select .ant-select-selector {
  background-color: #d0d3d4 !important;
  align-items: center !important;
  border-radius: 16px !important;
  width: 100% !important;
  height: 30px !important;
}

.ant-select-selection-search {
  color: white !important;
}

.ant-select-selection-item {
  color: white !important;
  font-family: 'Hero New' !important;
  font-weight: 400;
}

.ant-select-selector .ant-select-selection-placeholder {
  color: white !important;
  font-family: 'Hero New' !important;
  font-weight: 400;
}

.ant-select-arrow {
  color: white !important;
  height: 1% !important;
}

.badges-select .ant-select-arrow {
  color: black !important;
  transform: translateY(-15px) !important;
  height: auto !important;
}

.delivery-icon-container {
  display: flex;
  width: 56px;
  height: 56px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  background: #9EEF89;
}

 .delivery-container {
  display: flex;
  gap: 10px;
  /* justify-content: space-between; */
}


 .ant-modal-close-x {
  /* background-color: #D2D2D2; */
  border-radius: 50px;

 }

 .custom-yellow-border-rate .ant-rate-star {
  border-color: FFC24D;
}


@media (min-width: 600px) {
  .delivery-container {
    display: flex;
    /* justify-content: center; */
    gap: 20px;
  }
 }

 .total-balance {
  border-radius: 6px;
  background: #D0D3D4;
  display: flex;
  align-items: center;
  gap: 5px;
  height: 28px;
  justify-content: center;
}

.reward-img {
  border-radius: 20px;
  display: flex;
  border: 1px solid #D9D9D9;
  height: 133px;
  align-items: center;
  justify-content: center;
  gap: 8px;
}